<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="onClose"
      submit="Save"
      :loading="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <c-backbutton @onClick="isOpen = false" variant="secondary" />
          <h1 class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-3">
            {{ payload.id ? 'Edit Review' : 'Add Review' }}
          </h1>
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start gap-5">
          <div class="flex flex-col justify-start items-start gap-3">
            <div class="w-full">
              <c-text
                label="Review Type"
                placeholder="Enter Review Name"
                class="w-full"
                v-model="payload.reviewCycle"
                :rules="['required']"
              />
            </div>
            <div class="flex flex-col justify-start items-start gap-2 w-full">
              <span class="text-sm font-normal leading-5 text-darkPurple">
                Start Date
              </span>
              <div class="border border-romanSilver rounded-md pl-5 w-full">
                <div class="flex justify-start items-center gap-2 w-full">
                  <Icon icon-name="calendar" size="xs" />
                  <Divider vertical class="w-5" />
                  <datepicker
                    placeholder="Select Date"
                    input-class="date-input"
                    style="width:100%"
                    v-model="payload.startDate"
                    :rules="['required']"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-start items-start gap-2 w-full">
              <span class="text-sm font-normal leading-5 text-darkPurple">
                End Date
              </span>
              <div class="border border-romanSilver rounded-md pl-5 w-full">
                <div class="flex justify-start items-center gap-2 w-full">
                  <Icon icon-name="calendar" size="xs" />
                  <Divider vertical class="w-5" />
                  <datepicker
                    placeholder="Select Date"
                    input-class="date-input"
                    style="width:100%"
                    v-model="payload.endDate"
                    :rules="['required']"
                    :disabled-dates="{to: new Date(payload.startDate)}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-col gap-5">
            <template>
              <div class="flex flex-row justify-between w-full">
                <p class="font-normal text-sm text-jet">Review Cycles</p>
                <div class="w-32 flex flex-row justify-between items-center">
                  <div @click="reviewCycles.push({ cycleName: '', startDate: '', endDate: '', isCustomName: false })">
                    <div class="flex flex-row justify-start items-center gap-2 cursor-pointer">
                      <p class="text-sm text-flame">Add Cycle</p>
                      <Icon icon-name="icon-plus" class="text-flame" size="xs" />
                    </div>
                  </div>
                  <div class="cursor-pointer" @click="toggleCycles = !toggleCycles">
                    <Icon
                      :icon-name="toggleCycles ? 'chevronDown' : 'chevronRight'"
                      class="text-flame cursor-pointer" size="xs"
                    />
                  </div>
                </div>
              </div>
            </template>
            <div v-show="toggleCycles">
              <div class="w-full border border-dashed border-romanSilver px-3 py-3 rounded-md">
                <div class="flex flex-col justify-start gap-5">
                  <template v-if="reviewCycles.length">
                    <div v-for="(cycle, i) in reviewCycles" :key="i" class="relative border-b border-romanSilver">
                      <div class="flex justify-end items-center">
                        <c-checkbox
                          class="text-sm text-jet font-semibold"
                          @change="cycle.isCustomName = !cycle.isCustomName"
                          label="Custom Review Name"
                        />
                      </div>
                      <div class="flex flex-row justify-between items-center gap-2 relative mb-5">
                        <div class="flex flex-col justify-start items-start gap-3">
                          <div class="w-full">
                            <c-text
                              v-if="cycle.isCustomName"
                              placeholder="Enter Cycle Name"
                              class="w-full"
                              v-model="cycle.cycleName"
                              :rules="[].concat(reviewCycles.length > 1 ? ['required'] : [])"
                            />
                            <c-select
                              v-if="!cycle.isCustomName"
                              class="w-full text-sm text-darkPurple"
                              v-model="cycle.cycleName"
                              placeholder="--Select Cycle--"
                              :rules="['required']"
                              :options="['Mid-Year Review', 'Final-Year Review']"
                            />
                          </div>
                          <div class="flex flex-row justify-between items-center gap-2 w-full">
                            <div class="flex flex-col justify-start items-start gap-2 w-full">
                              <div class="border border-romanSilver rounded-md pl-5 pr-2 w-full">
                                <div class="flex justify-start items-center gap-2 w-full">
                                  <Icon icon-name="calendar" size="xs" />
                                  <div class="border-r border-romanSilver h-6" />
                                  <datepicker
                                    placeholder="--Start Date--"
                                    input-class="date-input"
                                    style="width:100%"
                                    v-model="cycle.startDate"
                                    :rules="['required']"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="flex flex-col justify-start items-start gap-2 w-full">
                              <div class="border border-romanSilver rounded-md pl-5 pr-2 w-full">
                                <div class="flex justify-start items-center gap-2 w-full">
                                  <Icon icon-name="calendar" size="xs" />
                                  <div class="border-r border-romanSilver h-6" />
                                  <datepicker
                                    placeholder="--End Date--"
                                    input-class="date-input"
                                    style="width:100%"
                                    v-model="cycle.endDate"
                                    :rules="['required']"
                                    :disabled-dates="{to: new Date(cycle.startDate)}"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="reviewCycles.length > 1" class="mt-5 cursor-pointer">
                          <div @click="reviewCycles.splice(i, 1)">
                            <Icon icon-name="delete" class="text-flame" size="xs" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex justify-center items-center">
                      <div @click="reviewCycles.push({ cycleName: '', startDate: '', endDate: '', isCustomName: false })">
                        <div class="flex flex-row justify-start items-center gap-2 cursor-pointer">
                          <p class="text-sm text-flame">Add Cycle</p>
                          <Icon icon-name="icon-plus" class="text-flame" size="xs" />
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
  import * as _ from "lodash"
  import { format } from "date-fns";
  import Datepicker from "vuejs-datepicker";
  import { ValidationObserver } from "vee-validate";
  import CBackbutton from "@scelloo/cloudenly-ui/src/components/back-button";
  import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox";
  import CSelect from "@scelloo/cloudenly-ui/src/components/select";
  import CText from "@scelloo/cloudenly-ui/src/components/text"
  import RightSideBar from "@/components/RightSideBar";
  import Icon from "@/components/Icon";
  import Divider from "@/components/divider";

  export default {
    components: {
      ValidationObserver,
      CBackbutton,
      CSelect,
      CText,
      CCheckbox,
      Datepicker,
      RightSideBar,
      Icon,
      Divider
    },
    data(){
      return {
        isOpen: false,
        isLoading: false,
        toggleCycles: true,
        reviewCycles: [],
        payload: {
          reviewCycle: '',
          startDate: '',
          endDate: '',
          isCustomName: false
        },
      }
    },
    methods: {
      toggle(payload){
        this.payload = payload
        this.reviewCycles = _.map(payload.cycles,
          cycle => ({...cycle, isCustomName: true})
        )
        this.isOpen = true
      },
      onClose(){
        this.isOpen = false
        this.isLoading = false
        this.reviewCycles = []
      },
      onSubmit(){
        this.isLoading = true

        const payload = {
          orgId: this.$orgId,
          id: this.payload?.id || null,
          cycles: this.reviewCycles,
          reviewCycle: this.payload.reviewCycle,
          startDate: format(new Date(this.payload.startDate), "yyyy-MM-dd"),
          endDate: format(new Date(this.payload.endDate), "yyyy-MM-dd"),
        }

        try {
          if(payload.id !== null) {
            this.$_updateOrgReviewCycles(payload).then(({ data }) => {
              this.$toasted.success(data.message, {duration: 3000})
              this.$emit("success", data)
              this.onClose()
            }).catch ((error) => {
              this.$toasted.error(error.message, {duration: 3000})
            })
          } else if (payload.id === null){
            this.$_postOrgReviewCycles(payload).then(({ data }) => {
              this.$toasted.success(data.message, {duration: 3000})
              this.$emit("success", data)
              this.onClose()
            }).catch ((error) => {
              this.$toasted.error(error.message, {duration: 3000})
            })
          }
        } catch (error) {
          this.$toasted.error(error.message, {duration: 3000})
        }
      },
    },
  };
</script>

<style>
  .rightSidebar{
    width: 520px !important;
  }
  .vdp-datepicker__calendar {
    margin-left: -100px !important;
    color: #321C3B !important;
  }
</style>
